<template>
  <SuppliersPage
    banner_location="games_home-top"
    supplier_router="EndUserGameSupplier"
    product_router="EndUserGameProduct"
    page_title="games"
  ></SuppliersPage>
</template>

<script>
import SuppliersPage from '@/components/endUser/suppliersPages/SuppliersPage.vue';

export default {
  name: 'Game',
  components: {
    SuppliersPage
  },
}
</script>